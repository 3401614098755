/**
 * submit-to
 * 为了【列表提交给城管局】功能而做的公用函数
 * @auhtor suyonggang
 */
export default {
    data() {
        return {
            submitToData: [],//接受4个参数 0:api 1:提交的id名,2:入参,3表格
            id_list: [],
            btnLoading: false,
        }
    },
    methods: {
        selectionTable(val) {
            this.id_list = val.map(item => {
                return item[this.submitToData[2]];
            });
        },

        async onSubmitTo(event) {
            console.log(event)
            let self = this;
            let idList = this.id_list
            let text = event.target.textContent || "操作";
            if (idList.length === 0) {
                self.$message.warning("请选中要" + text + "的项！");
                return;
            } else {
                self.$msgbox
                    .confirm("确定要" + text + "选中的项吗？", "提示", {
                        type: "warning"
                    })
                    .then(async function () {
                        self.btnLoading = true;
                        try {
                            let arr = self.submitToData[1]
                            let params = {
                                [arr]: idList
                            }
                            if (self.submitToData[4] === 0 || self.submitToData[4] == 1 || self.submitToData[4] == 2) {
                                params.type = self.submitToData[4]
                            }
                            await self.submitToData[0](params);
                            self.$notify({
                                title: "成功",
                                message: text + "成功",
                                type: "success"
                            });
                            self.$search(self[self.submitToData[3]]);

                        } catch (e) {
                            // console.error(e);
                        }
                    }).catch((e) => { });
                self.btnLoading = false;
                self.id_list = []
            }
        },
    }
}