<template>
  <div class="font">
    <ayl-berad-nav :nav="nav" />
    <div class="content-box mt20px">
      <div class="content-main">
        <ayl-table :table="table" @selection-change="selectChange">
          <div slot="ctrl-button">
            <el-button class="btn" style="margin-left: 20px" @click="onAdd">+ 新增</el-button>
            <el-button class="ml40px" type="info" style="float: right;" plain @click="deleteData">删除</el-button>
            <el-button class="ml40px" type="info" style="float: right;" plain @click="onSubmitTo">提交</el-button>
          </div>
        </ayl-table>
      </div>
    </div>
    <!-- {{isShowDialog}} -->
    <ayl-dialog
      :visible.sync="isShowDialog"
      :viewUrl="viewUrlForDialog"
      v-model="dataForDialog"
      :async="true"
      :showBottomBtn="false"
    ></ayl-dialog>
  </div>
</template>

<script>
import SubmitTo from "@/mixins/submit-to";
export default {
  mixins: [SubmitTo],
  data() {
    // eslint-disable-next-line no-unused-vars
    const vm = this;
    return {
      debug: 0,
      nav: [{ name: "安全事故记录" }],

      // 提交
      submitToData: [
        this.$api_hw.operationsSafetyManagement_commonServiceCommitRecord,
        "idList",
        "materialId",
        "table",
        0
      ],

      //弹窗有关
      viewUrlForDialog: ``,
      isShowDialog: false,
      dataForDialog: null,

      // 标段名称下拉
      bidList: [],
      table: {
        api: vm.$api_hw.operationSafetyManagement_getAccidentList,
        query: {
          bidId: null, // 关联标段编号
          startDate: null, // 开始日期
          endDate: null, // 结束日期
          careUnitName: null, // 养护企业名称
          manageUnit: null, //主管单位名称
          objectType: 0
        },

        // 选中删除的数据id
        id_list: [],
        // 是否显示分页
        // hidePagination: true,
        // 表格查询的条件组件
        searchData: [
          {
            type: "cascader_radio",
            title: "标段名称",
            model: "bidId",
            placeholder: "请选择",
            option: []
          },
          {
            type: "select",
            title: "养护企业",
            model: "careUnitName",
            placeholder: "请选择",
            option: []
          },
          {
            type: "select",
            title: "主管单位",
            model: "manageUnit",
            placeholder: "请选择",
            option: []
          },
          {
            type: "dateSection_hw",
            title: "自查日期",
            isOptions: true,
            model: [],
            modelName: ["startDate", "endDate"]
          }
        ],
        columns: [
          {
            type: "selection",
            width: "50px"
          },
          {
            title: "序号",
            width: "50px",
            $index: "index",
            align: "center"
          },
          {
            title: "标段名称",
            key: "bidName",
            width: "120px"
          },
          {
            title: "养护企业",
            showTooltip: true,
            key: "careUnitName"
          },
          {
            title: "主管单位",
            showTooltip: true,
            key: "manageUnit"
          },
          {
            title: "事故日期",
            key: "accidentDate",
            filter: "str2ymd",
            width: "170px"
          },
          {
            title: "事故描述",
            width: "140px",
            key: "accidentRemark"
          },
          {
            title: "处置方式",
            width: "140px",
            key: "handleType"
          },
          {
            title: "处置结果",
            width: "140px",
            key: "handleResult"
          },
          {
            title: "状态",
            width: "100px",
            align: "center",
            key: "checkStatusDes"
          },
          {
            title: "审核意见",
            width: "100px",
            key: "checkResult"
          },
          {
            title: "操作",
            width: "155px",
            align: "center",
            render(h, ctx) {
              return h("div", [
                h(
                  "el-button",
                  {
                    style: {
                      color: "#1ABC9C"
                    },
                    props: {
                      type: "text"
                    },
                    on: {
                      click: vm.onInfo.bind(this, ctx.row)
                    }
                  },
                  "查看"
                ),
                h(
                  "el-button",
                  {
                    style: {
                      color:
                        ctx.row.checkStatus === "1" ||
                        ctx.row.checkStatus === "2"
                          ? ""
                          : "#1ABC9C"
                    },
                    props: {
                      type: "text",
                      disabled:
                        ctx.row.checkStatus === "1" ||
                        ctx.row.checkStatus === "2"
                          ? true
                          : false
                    },
                    on: {
                      click: vm.onDisposal.bind(this, ctx.row)
                    }
                  },
                  "处置"
                )
              ]);
            }
          }
        ]
      }
    };
  },

  methods: {
    //新增
    onAdd() {
      //传views的url
      this.viewUrlForDialog =
        "/operation-safety-management/safety-incident-record/add-safety-incident";
      //传参给弹窗
      this.dataForDialog = {};
      this.isShowDialog = true; //显示弹窗
    },

    // 选中
    selectChange(table) {
      this.log(table);
      this.id_list = table.map(item => {
        return item.accidentId;
      });
    },
    // 删除(批量)
    async deleteData() {
      if (this.id_list.length < 1) {
        this.$message({
          message: "请选择需要删除的数据！",
          type: "warning"
        });
        return;
      }
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          let params = {
            idList: [...this.id_list]
          };
          this.$api_hw.operationSafetyManagement_deleteAccidentInfo(params);
          this.$message({
            type: "success",
            message: "删除成功!"
          });
          this.$search(this.table);
        })
        .catch(err => {
          console.log(err);
        });
    },

    //查看
    onInfo(val) {
      this.log(val);
      //传views的url
      this.viewUrlForDialog =
        "/operation-safety-management/safety-incident-record/info-safety-incident";
      //传参给弹窗
      this.dataForDialog = {
        accidentId: val.accidentId //事故记录ID
      };
      //显示弹窗
      this.isShowDialog = true;
    },
    // 处置
    onDisposal(val) {
      this.log(val);
      //传views的url
      this.viewUrlForDialog =
        "/operation-safety-management/safety-incident-record/disposal-safety-incident";
      //传参给弹窗
      this.dataForDialog = {
        accidentId: val.accidentId //事故记录ID
      };
      //显示弹窗
      this.isShowDialog = true;
    }
  },
  async mounted() {
    // 获取表格数据
    await this.$search(this.table);

    // 获取标段数据
    await this.$api_hw.common_getBidNameAndIdList({}).then(res => {
      this.table.searchData[0].option = res;
    });
    // 获取养护
    await this.$api_hw.districtInfo_getDistinctCareUnit({}).then(res => {
      this.table.searchData[1].option = res.map(item => {
        return { value: item, label: item };
      });
    });
    // 获取主管单位下拉
    await this.$api_hw.common_getDistinctManageUnit({}).then(res => {
      this.table.searchData[2].option = res.map(item => {
        return { value: item, label: item };
      });
    });

    BUS.$on(BUSEVENT.REFRESH_SAFETY_INCIDENT_RECORD, () => {
      this.$search(this.table);
    });
  }
};
</script>
<style lang='sass' scoped>
.btn
  width: 64px
  height: 28px
  border: 1px solid rgba(26,188,156,1)
  border-radius: 4px
  color: #1ABC9C
/deep/ .el-button--primary 
  color: #FFFFFF
  background-color: #1ABC9C
  border-color: #1ABC9C

.detail-main
  margin: 0px 30px
  font-size: 14px
  font-family: Microsoft YaHei
  font-weight: 400
  color: rgba(102,102,102,1)
  line-height: 46px
</style>